// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeCircle,
    placeAngle,
    placeDash,
    placePlay,
    placeLogo,
    placePause,
    placeFullAngle
} from '../../../common/edliy_utils-geometric';
const Boxes = {
    box1: function () {
    //Create the Board
    JXG.Options.board.minimizeReflow = 'none';
    JXG.Options.point.showInfobox =false;
    JXG.Options.line.highlight=false;
    var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 9, 8, -9],
    keepaspectratio: true, axis:true, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
   //suspend updates
    //brd1.suspendUpdate();
    // Layering
    brd1.options.layer['image']=15;
    brd1.options.layer['text']=16;
    brd1.options.layer['axis']=18;
    //Make the board responsive
    makeResponsive(brd1);
    // Creat titleStyle
    placeTitle(brd1, 'Sine Function', '(Drag the Highlighted Point on the Circle)');
    placeLogo(brd1);
    var play = placePlay(brd1,'left');
    var pause = placePause(brd1,'right');
    hoverMe(brd1, play, 'Press to Play', 0, -20);
    hoverMe(brd1, pause, 'Press to Pause', 0, -20);
    //dragMe(brd1, 5, 5);
    var pts1 = [-15,0];
    var pts2 = [15,0];
    var pts3 = [15, -5];
    var pts4 = [-15, -5];
    var pts6= [-4, 6];
    var pts7 =[-4, 4];
    var j=3;

    //placeArrow(brd1, pts6, pts7, 'red');
    //placeGravity(brd1, [7,3],[7, 1], 'g =9.8m/s^2');
    //placeRec(brd1, [-10, -20], [10, -20], [10, 0], [-10, 0], 'lightblue');
    //brd1.create('polygon', [[-10, -20], [10, -20], [10, 0], [-10, 0]],{fillOpacity:0.5, color:'lightblue', borders:{visible:false}, highlight:false, vertices:{visible:false}});
    //var soil =
    //placeImage(brd1,'/assets/iceberg.svg', -8.5, -7., 9.6, 0);
    //placeImage(brd1,'/assets/iceberg.svg', ()=>k[j.valueOf()], ()=>i[j.valueOf()][0], ()=>i[j.valueOf()][1], 0);
    //placePoint(brd1, 1, 1, 4, 'white', 'black');
    //var pt = placePoint(brd1,0, ()=>i[j.valueOf()][0], 1, 'white', 'black');
    var seg = placeCircle(brd1, [-6, 0], [-6, function(){return j}], 2, 'grey');
    seg.setAttribute({fillOpacity:0.5, strokeWidth:4});
    var loc =placeGlider(brd1, seg, -6, 0, 3, 'white', 'black');

    play.on('down' ,function(){loc.startAnimation(-1, 300, 20)});
    pause.on('down' ,function(){loc.stopAnimation()});
    placeDash(brd1, [()=>loc.X(), ()=>loc.Y()], [()=>loc.X(), 0], 1, 'green');
    placeDash(brd1, [()=>loc.X(), ()=>loc.Y()], [0, ()=>loc.Y()], 1, 'red');
    placeArrow(brd1, [-6, 0], [()=>loc.X(), ()=>loc.Y()], 2,'black');
    //hoverMe(brd1, loc, '(x, y)', 0, 20);
  //  var baro=brd1.create('image', ['/assets/barometer.svg', [-0.275, ()=>loc.Y()-0.5], [1, 1]], {highlight:false});
  //  brd1.create('image', ['/assets/scuba.svg', [0, ()=>loc.Y()-0.5], [3, 3]], {highlight:false});
  //  placeImage(brd1,'/assets/puffy.svg', 4, -5., 1, 0, {...options});
  //  placeImage(brd1,'/assets/fish.svg', 2, -9., 1, 0);
  //  placeImage(brd1,'/assets/fish-2.svg', 7, -7., 1, 0);
  //  var plus =placeImage(brd1,'/assets/plus.svg', -9., -9, 2, 0);
  //  var minus = placeImage(brd1,'/assets/minuss.svg', -13, -9, 2, 0);

    var horz =placeLine(brd1,[()=>loc.X()-60, ()=>loc.Y()], [()=>loc.X()+60, ()=>loc.Y()], 1, 'grey');
    horz.setAttribute({visible:false});
    //placeRuler(brd1, [6, 0],[6, function(){return loc.Y();}], '5m');
    //placeLeftText(brd1, 6.25, function(){return 0.5*loc.Y()}, function(){return '' + (-loc.Y()).toFixed(2) + ' m'});
  //  plus.on('down', function(){if(j<6){j+=0.5;}else{return}});
  //  minus.on('down', function(){if(j>2){j-=0.5;}else{return}});
    //var ang =brd1.create('angle', [[1,-10], [0,-10], loc],{radius:1, center:{visible:false}, radiusPoint:{visible:false}});
    var ang =placeFullAngle(brd1, [-5, 0], [-6, 0], [()=>loc.X(), ()=>loc.Y()], ()=>0.5*j.valueOf(), 'red');
    brd1.create('functiongraph',[function(x){return j.valueOf()*Math.sin(x)}, 0, ()=>ang.Value()],{strokeColor:'red', strokeWidth:2, highlight:false});
    placePoint(brd1, ()=>ang.Value(), ()=>loc.Y(), 3, 'white', 'black');
    placeDash(brd1, [()=>ang.Value(), ()=>loc.Y()], [()=>ang.Value(), 0], 1, 'grey');
    placeDash(brd1, [()=>ang.Value(), ()=>loc.Y()], [0, ()=>loc.Y()], 1, 'grey');
    placeText(brd1, ()=>ang.Value(), -0.5, '&alpha;');
    placeText(brd1, -0.5, ()=>loc.Y(), 'y');
    placeText(brd1, ()=>0.5*j.valueOf()*Math.cos(ang.Value()+0.25)-6, ()=>0.5*j.valueOf()*Math.sin(ang.Value()+0.25), 'r');
    placeText(brd1, ()=>(j.valueOf()+1.0)*Math.cos(ang.Value())-6, ()=>(j.valueOf()+1.0)*Math.sin(ang.Value()), '(x, y)');
    //placeText(brd1, 3.14, 8, function(){return 'y =' + j.valueOf()+'sin('+'&alpha;'+')'});
    brd1.create('text', [3.14, 8, function(){return 'y =r sin(&alpha;)'}],{anchorX:'middle', highlight:false, CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd1.canvasWidth/500.)}, fixed:true, highlight:false});

    //placeImage(brd1,'/assets/scuba.svg', 0, ()=>loc.Y(), 3., 0);
    //placeLine(brd1, [0,0], [0, -20], 4, 'black');
    //var ground =placeRec(brd1, pts1, pts2, pts3, pts4, 'grey');
    //var stone = placeImage(brd1,'/assets/stone.svg', -2, -0.5, 4, 0);
    //var wall = placeImage(brd1,'/assets/wall.svg', 2, -0., 4., 0);
    //var bull = placeImage(brd1,'/assets/bulls.svg', -5.25, -0.65, 4.0, 0);
    //var dog =placeImage(brd1,'/assets/monkey1.svg', 0.25, 3, 1.5, 0);
    //var shuffle=placeImage(brd1,'/assets/shuffle.svg', 3, 6, 2., 0)
    //var stone =brd1.create('segment',[[-2.,0],[3, 5]],{strokeColor:'grey', strokeWidth:10, highlight:false});
    ///var wallText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the wall on the  plank.');
    //var bullText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the bull on the plank.');
    //var dogText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the monkey on the plank.');
    //var groundText =placeText(brd1, 0, 9, 'Tap on to apply the force exerted by the floor on the plank');
    //placeText(brd1, 0, 8, '()=>'Total '+ (4-i-j-k-l).valueOf() +' objects remaining');
//////////////////////////////////////////////////////////////
    //brd1.unsuspendUpdate();
                      },
              }
export default Boxes;
