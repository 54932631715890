<template>
  <div>
    <h3 ref="ia">
      Sine & Cosine Functions
    </h3>
    <p>
      Consider an object that is submerged in a fluid. The object experiences pressure due to weight of the fluid above it.
      Pressure experienced by an object submerged in a fluid at rest is called hydrostatic pressure.
    </p>
    <h3 ref="ja">
      Magnitude of Hydrostatic Pressure
    </h3>
    <p>
      The magnitude of hydrostatic pressure experienced by an object submerged in a fluid depends upon
      the density of the fluid and the depth.
      <br>
      $$P = \rho g h$$
      where \(\rho\) is the density of the fluid, \(g\) is the acceleration due to gravity and \(h\) is the depth.
      <br>
    </p>
    <!--<h3 ref="ka">Example: Free Body Diagram of an Inclined Wooden Plank</h3>
<p> In this example, we will consider a massless wooden plank that is resting on a wall. The plank is in contact with two external objects: the floor and the wall.
      To construct the free body diagram of the plank, we will remove these surrounding objects and replace them by the forces exerted by them on the plank.
      </p>
      <v-layout justify-center>
       <h5 style="text-align:center">Figure 1(c): Next, we replace the floor by the force it exerts on the plank. </h5>
      </v-layout> -->
    <h3 ref="pg">
      MagicGraph | Measuring the Depth of an Iceberg
    </h3>
    <p>
      Billy is an environmental scientist specializing in the study of icebergs.
      He wants to measure the depth of an iceberg. <br>
      He doesn't have a measuring tape or rope that is long enough to span the depth. <br>
      He recalls that hydrostatic pressure \(P =\rho g h\). <br>
      He already knows the density of water is \(\rho = 1000 \text{ kg/m}^3\). <br>
      He figures out that if he measures the pressure P at the depth
      of the iceberg, he can use it calculate the depth of the iceberg as \(h = P/\rho g \).
    </p>

    <v-layout align-center
              justify-center
              row
              wrap
    >
      <v-flex xs12
              sm7
              md7
              lg7
              xl7
              class="font-weight-light"
              :class="{'headline': $vuetify.breakpoint.lgAndUp,'body-2': $vuetify.breakpoint.mdOnly, 'body-2': $vuetify.breakpoint.smAndDown}"
      >
        <v-responsive>
          <v-layout align-center justify-center>
            <div id="jxgbox1" class="edliy-box-about" />
          </v-layout>
        </v-responsive>
      </v-flex>
      <v-flex xs12
              sm5
              md5
              lg5
              xl5
      >
        <v-layout align-start justify-center>
          <div id="equations">
            <div id="interior1">
              <div id="step1">
                <p> &nbsp; </p>
              </div>
              <br> <br>
              <div id="step2">
                <p> &nbsp; </p>
              </div>
              <br><br>
            </div>
            <div id="interior2">
              <div id="step3">
                <p> &nbsp; </p>
              </div>
              <div id="step3-1">
                <p> &nbsp; </p>
              </div>
              <div id="step3-2">
                <p> &nbsp; </p>
              </div>
              <div id="step3-3">
                <p> &nbsp; </p>
              </div>
            </div>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'Sine',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Sinusoidal Function';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'What is a Free Body Diagram?', img:'/assets/number-1.svg', action: () => this.goto('ia')},
      {title: 'Purpose of FBD', img:'/assets/number-2.svg', action: () => this.goto('ja')},
      {title: 'Example of FBD', img:'/assets/number-3.svg', action: () => this.goto('ka')},
      {title: 'MagicGraph' ,img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newPhys =true;
    this.$store.commit('navigation/replacePhys', newPhys);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule =true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo: {
          title: 'Sinusoidal Function',
          //titleTemplate: '%s - Learn interactively',
          meta: [
                  {vmid: 'description', name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
   },
}
</script>
<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
@import 'src/styles/screen-sizes.scss';
</style>
